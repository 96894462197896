<template>
  <div class="page sonuc">
    <div class="formcontainer">
      <div class="formbox">
        <!-- <div class="hub_header"> -->
        <h2>Başvuru Durumu</h2>
        <tabs-new-basvuru current="3" hide="6"></tabs-new-basvuru>

        <!-- <div class="basvuruno">
            <div class="basvurunobox">
              Başvuru No
              <div class="num">{{application.appNo}}</div>
            </div>
          </div> -->

        <!-- </div> -->

        <!-- <tabs-basvuru current="7" hide="5"></tabs-basvuru> -->

        <!-- <div class="formhead">

          <div>⚫ Bu sayfada başvurunuza ilişkin adımların tümüne ilişkin durum ve sonuç bilgileri yer almaktadır.</div>
        </div>-->

        <div class="formpart">
          <div class="formfull">
            <div class="hubbox">
              <!-- <div>
                <h4>Sayın {{ application.contact.name }}</h4>
                <h3>{{ application.appNo }} no'lu başvurunuz alınmıştır.</h3>

                <div class="header">
                  <h4 class="yello_d">
                    Hesap yöneticilerimiz başvurunuzu inceleyecektir.
                  </h4>
                </div>

                <p>
                  Başvurunuzun değerlendirme aşamaları ve İleti Yönetim Sistemi
                  platformunun Hizmet Sağlayıcılar için aktif olmasına ilişkin
                  bildirimler telefon ve e-posta yoluyla yapılacaktır.
                  <br /><b>Başvuru numaranızın</b> yer aldığı size gönderilmiş
                  e-posta'yı <b>lütfen saklayınız</b>.
                </p>

                <div class="half"></div>
                <div class="btn half fl">
                  <router-link
                    to="/hizmet-saglayici/basvuru/sonuc-detay-on-basvuru"
                    >Detayları İncele</router-link
                  >
                </div>
              </div> -->

              <div v-if="CONFIRMED">
                <h3>
                  Hesap yöneticilerimiz {{ application.appNo }} no'lu
                  başvurunuzu sonuçlandırdı.
                </h3>

                <div class="header">
                  <h3 style="color: #229f37">Başvurunuz Onaylandı.</h3>
                </div>

                <p>
                  Aşağıdaki buton aracılığıyla genel başvurunuza devam
                  edebilirsiniz.
                </p>

                <div class="half"></div>
                <div class="btn half fl" v-if="!CONFIRMED">
                  <router-link
                    to="/hizmet-saglayici/basvuru/sonuc-detay-on-basvuru"
                    >Detayları İncele</router-link
                  >
                </div>
                <div class="btn half fl" v-if="CONFIRMED">
                  <router-link to="/hizmet-saglayici/basvuru/bilgi-girisi"
                    >Başvuruya Devam Et</router-link
                  >
                </div>
              </div>

              <div v-if="SUBMITTED">
                <h4>Sayın {{ application.contact.name }}</h4>
                <h3>{{ application.appNo }} no'lu başvurunuz alınmıştır.</h3>

                <div class="header">
                  <h4 class="yello_d">
                    Hesap yöneticilerimiz başvurunuzu inceleyecektir.
                  </h4>
                </div>

                <div>
                  <p>
                    Başvurunuzun değerlendirme aşamalarına ilişkin bildirimler
                    telefon ve e-posta yoluyla yapılacaktır.
                    <br /><b>Başvuru numaranızın</b> yer aldığı size gönderilmiş
                    e-posta'yı <b>lütfen saklayınız</b>.
                  </p>
                </div>

                <div class="half"></div>
                <div class="btn half fl">
                  <router-link
                    to="/hizmet-saglayici/basvuru/sonuc-detay-on-basvuru"
                    >Detayları İncele</router-link
                  >
                </div>
              </div>

              <div v-if="EDITED">
                <h4>Sayın {{ application.contact.name }}</h4>
                <h3>
                  {{ application.appNo }} no'lu başvuru güncellemeniz
                  alınmıştır.
                </h3>

                <div class="header">
                  <h4 class="yello_d">
                    Hesap yöneticilerimiz başvurunuzu tekrar inceleyecektir.
                  </h4>
                </div>

                <div>
                  <p>
                    Başvurunuzun değerlendirme aşamalarına ilişkin bildirimler
                    telefon ve e-posta yoluyla yapılacaktır.
                    <br /><b>Başvuru numaranızın</b> yer aldığı size gönderilmiş
                    e-posta'yı <b>lütfen saklayınız</b>.
                  </p>
                </div>

                <div class="half"></div>
                <div class="btn half fl">
                  <router-link
                    to="/hizmet-saglayici/basvuru/sonuc-detay-on-basvuru"
                    >Detayları İncele</router-link
                  >
                </div>
              </div>

              <div v-if="REJECTED">
                <h3>
                  {{ application.appNo }} no'lu başvurunuz incelenmiş,
                  bilgilerinizde/belgelerinizde değişiklik yapılması gerektiği
                  tespit edilmiştir.
                </h3>

                <div class="header">
                  <img src="@/assets/img/ico_exclamination.svg" alt />
                  <h4 style="color: #ce2f27">İşlem yapmanız bekleniyor.</h4>
                </div>

                <p>
                  Başvurunuza ilişkin detayları inceleyip gerekli değişiklikleri
                  yapmanız halinde bilgilerinizin/belgelerinizin yeniden
                  kontrolüyle tarafınıza bildirim yapılacaktır.
                </p>

                <div class="half"></div>
                <div class="btn half fl">
                  <router-link
                    to="/hizmet-saglayici/basvuru/sonuc-detay-on-basvuru"
                    >Belgeyi Düzenle</router-link
                  >
                </div>
              </div>
            </div>

            <!-- <div class="formfooter">
              <div class="text">
                Talep, öneri veya şikâyetiniz olması halinde
                <b>138 İleti Yönetim Sistemi Çağrı Merkezi</b>'ni arayarak bizimle iletişim
                kurabileceksiniz.
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "BasvuruSonucOnBasvuru",

  computed: {
    ...mapState("applicant", ["application"]),

    SUBMITTED() {
      return this.application.step === "INITIAL_SUBMITTED_TO_AGENT";
    },

    REJECTED() {
      return this.application.step === "INITIAL_AGENT_REJECTED";
    },

    CONFIRMED() {
      return this.application.step === "INITIAL_AGENT_CONFIRMED";
    },

    EDITED() {
      return this.application.step === "INITIAL_DOCUMENTS_EDITED";
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: flex-end;
  margin: 2vh 0;
}

.hub_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.hubbox {
  background: #fdfbfa;
  padding: 4vh;
  border-radius: 0.5vw;
  margin: 5vh 0;
  position: relative;
}

.hubbox .desc {
  margin: 2vh 0;
}

.basvuruno .basvurunobox {
  background: #d8d8d8;
  padding: 1vw;
}

.basvuruno {
  width: unset;
}
.basvuruno .basvurunobox {
  margin-right: 0;
}
</style>
